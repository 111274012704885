<template>
  <div>
    <b-form-group id="name-group" label="Full Name" label-for="title">
      <b-form-input
        id="name"
        v-model="model.name"
        placeholder="Full Name"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
import HelpStore from "@/store/helpstore.js";

export default {
  name: "AnnotatorNewEdit",
  props: ["annotator"],
  data() {
    return {
      model: this.annotator
        ? this.annotator
        : {
            id: null,
            name: '',
          },
    };
  },
  mounted() {
    HelpStore.item = this.model;
  },
};
</script>
